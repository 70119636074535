import React, { Component, Suspense } from "react";
import BrowserRouter from "react-router-dom/BrowserRouter";
import Route from "react-router-dom/Route";
import Switch from "react-router-dom/Switch";
import ScrollToTop from "./ScrollToTop.jsx";
import "../styles/Global.scss";
import { ReactComponent as Logo } from "../assets/Logo.svg";
import Seo from "./Seo.jsx";

const HomePage = React.lazy(() => import("../pages/home_page/index.jsx"));
const Layout = React.lazy(() => import("./Common.jsx"));
const NotFound = React.lazy(() => import("./NotFound.jsx"));
const Onboarding = React.lazy(() =>
  import("../pages/my_umeme/on_boarding.jsx")
);
const Investors = React.lazy(() => import("../pages/investors/index.jsx"));
const FinancialReports = React.lazy(() =>
  import("../pages/investors/financial_reports.jsx")
);
const Reports = React.lazy(() => import("../pages/reports/index.jsx"));
const ReportsPage = React.lazy(() => import("../pages/reports/category.jsx"));
const StoryPage = React.lazy(() => import("../pages/stories/story.jsx"));
const AboutPage = React.lazy(() => import("../pages/about/index.jsx"));
const SuppliersPage = React.lazy(() => import("../pages/suppliers/index.jsx"));
const CustomersPage = React.lazy(() => import("../pages/customers/index.jsx"));
const CapitalProjectsPage = React.lazy(() =>
  import("../pages/projects/index.jsx")
);
const ProjectPage = React.lazy(() => import("../pages/projects/project.jsx"));
const StoriesPage = React.lazy(() => import("../pages/stories/index.jsx"));
const CareersPage = React.lazy(() => import("../pages/careers/index.jsx"));
const JobsPage = React.lazy(() => import("../pages/careers/jobs.jsx"));
const JobPage = React.lazy(() => import("../pages/careers/job.jsx"));
const CategoryPage = React.lazy(() =>
  import("../pages/careers/categories.jsx")
);
const OutagesPage = React.lazy(() => import("../pages/outages/index.jsx"));
const TendersPage = React.lazy(() => import("../pages/tenders/index.jsx"));
const Page = React.lazy(() => import("../pages/index.jsx"));
const SafetyPage = React.lazy(() => import("../pages/safety/index.jsx"));
const TipPage = React.lazy(() => import("../pages/safety/tip.jsx"));
const HelpPage = React.lazy(() => import("../pages/help/index.jsx"));
const FaqsPage = React.lazy(() => import("../pages/help/faqs.jsx"));
const YakaMangu = React.lazy(() => import("../pages/yaka_mangu/index.jsx"));
const DocumentsPage = React.lazy(() => import("../pages/help/documents.jsx"));
const CalculatorPage = React.lazy(() =>
  import("../pages/calculator/index.jsx")
);
const MakePaymentsPage = React.lazy(() =>
  import("../pages/make_payments/index.jsx")
);
const ReleasePage = React.lazy(() => import("../pages/releases/release.jsx"));
const ReleasesPage = React.lazy(() => import("../pages/releases/index.jsx"));
const CgPage = React.lazy(() =>
  import("../pages/corporate_governance/index.jsx")
);
const BcPage = React.lazy(() =>
  import("../pages/corporate_governance/board_committee.jsx")
);
const CsrPage = React.lazy(() => import("../pages/csr/index.jsx"));
const SmtPage = React.lazy(() =>
  import("../pages/corporate_governance/smt.jsx")
);
const BodPage = React.lazy(() =>
  import("../pages/corporate_governance/bod.jsx")
);
const EdmPage = React.lazy(() =>
  import("../pages/energy_distribution_map/index.jsx")
);
const MagazinePage = React.lazy(() => import("../pages/magazine/index.jsx"));
const AnnualReports = React.lazy(() =>
  import("../pages/annual_report/index.jsx")
);
const GetConnected = React.lazy(() =>
  import("../pages/my_umeme/getConnected.jsx")
);
const Cookie_policy = React.lazy(() =>
  import("../pages/policies/cookie_policy.jsx")
);
const Terms_of_use = React.lazy(() =>
  import("../pages/policies/terms_of_use.jsx")
);
const Privacy_policy = React.lazy(() =>
  import("../pages/policies/privacy_policy.jsx")
);
const LargePowerUsersPage = React.lazy(() =>
  import("../pages/large_power_users/index.jsx")
);
const E_Sefuliya = React.lazy(() => import("../pages/E_Sefuliya/index.jsx"));
const TID = React.lazy(() => import("../pages/TID/index.jsx"));
const Success = React.lazy(() => import("../pages/Success/index.jsx"));
const DomesticCustomers = React.lazy(() =>
  import("../pages/domestic_customers/index.jsx")
);
const GetApp = React.lazy(() => import("../pages/customers/getApp.jsx"));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
    this.capitalizeFirstLetter = this.capitalizeFirstLetter.bind(this);
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  componentDidMount() {
    const currentYear = new Date().getFullYear();
    const reportPath = `/annual-report-${currentYear - 1}`;
    // NProgress.done();
    window.addEventListener("offline", function(e) {
      console.log("Offline, please check your internet connection");
      // alertifyjs.error('You are offline, please check your internet connection', 3, function () { console.log('dismissed'); });
    });
    // console.log(window.location.pathname)
    // let path = window.location.pathname;
    // if (path === "/") {
    //   document.title = "UMEME - Powering Uganda"
    // } else {
    //   let newPath = path.substr(1);
    //   document.title = this.capitalizeFirstLetter(newPath) + " - UMEME - Powering Uganda"
    // }
  }
  render() {
    //consitional rendering
    return (
      <BrowserRouter basename="/">
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <SF>
                <Seo
                  title="UMEME - Powering Uganda"
                  description="Uganda’s main electricity distribution company, listed on the We operate a 20 year electricity distribution concession from the Government of Uganda."
                  keywords="UMEME, power, energy, yaka, amasanyalaze, electricity, Company, distribution, Uganda Securities Exchange, use, uganda, supply, customers, maintenance, Nairobi Securities Exchange, nse, East Africa, Africa, Kampala, Industrial, Commercial, Domestic, Stations"
                />
                <HomePage />
              </SF>
            )}
          />
          <Route
            exact
            path="/myumemeonline/"
            render={() => (
              <SF>
                <Seo
                  title="My UMEME Online"
                  description="Personalise your UMEME online experience. Take advantage of our simple tools; Pay all your bills, log all your complaints (easy to follow up) and so much more, all in one place."
                  keywords="Personalise, tools, get started, customers, experience, manage account, self service"
                />
                <Onboarding />
              </SF>
            )}
          />

          <Route
            exact
            path="/stories/:id"
            render={(props) => (
              <SF>
                <StoryPage {...props} />
              </SF>
            )}
          />
          <Route
            exact
            path="/stories"
            render={() => (
              <SF>
                <Seo
                  title="Stories"
                  description="Read the latest company news on the blog"
                  keywords="Umeme, stories, blog, updates"
                />
                <StoriesPage />
              </SF>
            )}
          />
          <Route
            exact
            path="/press-media/:id"
            render={(props) => (
              <SF>
                <ReleasePage {...props} />
              </SF>
            )}
          />
          <Route
            exact
            path="/press-media"
            render={() => (
              <SF>
                <Seo
                  title="Press and media"
                  description="Read about recently press and media at UMEME."
                  keywords="Press, media, news umeme"
                />
                <ReleasesPage />
              </SF>
            )}
          />

          <Route
            path="/tariffs"
            render={() => (
              <SF>
                <Seo
                  title="Power Tariffs"
                  description="In line with the Electricity (Application for Permits, License and Tariff Review) Regulations, 2007 and the Electricity Act, 1999 (Cap 145 of the Laws of Uganda), the Electricity Regulatory Authority (ERA) reviewed and approved electricity tariffs in accordance with the principles of tariff calculation and the terms of supply of the licensees in the Electricity Supply Industry. Umeme Limited is required to inform its customers of the tariffs approved by ERA."
                  keywords="Power, Tariffs, about, Electricity, Retail, Customer"
                />
                <Page banner_id="259" content_id="273" />
              </SF>
            )}
          />
          <Route
            path="/download-umeme-app"
            render={() => (
              <SF>
                <Seo
                  title="Download Umeme App"
                  description="Download Umeme App"
                  keywords="Power, Download, Umeme App"
                />

                <GetApp />
              </SF>
            )}
          />
          <Route
            exact
            path="/investor-relations"
            render={() => (
              <SF>
                <Seo
                  title="Investors"
                  description="In this section you’ll find all investor essential information e.g. Annual reports, Quarterly statements etc."
                  keywords="Investors, relations, reports, statements, Annual, AGM, Announcements, Financial, Results, Interim, Facts and figures, Capital investments, Sales, Number of customers, Stock price, Publications"
                />
                <Investors />
              </SF>
            )}
          />
          <Route
            exact
            path="/investor-relations/reports"
            render={() => (
              <SF>
                <Seo
                  title="Reports"
                  description="In this section you’ll find all investor essential information e.g. Annual reports, Quarterly statements etc."
                  keywords="Investors, relations, reports, statements, Annual, AGM, Announcements, Financial, Results, Interim, Facts and figures, Capital investments, Sales, Number of customers, Stock price, Publications"
                />
                <Reports />
              </SF>
            )}
          />

          <Route
            exact
            path="/investor-relations/reports/:id"
            render={(props) => (
              <SF>
                <ReportsPage {...props} />
              </SF>
            )}
          />
          <Route
            path="/investors/financial"
            render={() => (
              <SF>
                <FinancialReports />
              </SF>
            )}
          />
          <Route
            exact
            path="/about"
            render={() => (
              <SF>
                <Seo
                  title="About"
                  description="Uganda’s main electricity distribution company, listed on the We operate a 20 year electricity distribution concession from the Government of Uganda."
                  keywords="UMEME, power, energy, yaka, amasanyalaze, electricity, Company, distribution, Uganda Securities Exchange, use, uganda, supply, customers, maintenance, Nairobi Securities Exchange, nse, East Africa, Africa, Kampala, Industrial, Commercial, Domestic, Stations"
                />
                <AboutPage />
              </SF>
            )}
          />
          <Route
            exact
            path="/corporate-social-responsibility"
            render={() => (
              <SF>
                <Seo
                  title="Corporate Social Responsibility"
                  description="Umeme’s Corporate Social Responsibility Program continues to expand and we are proud to be changing lives positively mainly through collaboration with charity organizations. Our CSR Agenda is driven by the fact that the organisation depends on the economic, environmental and social wellbeing of the society hence the obligation to ensure business processes contribute to its sustainability. In doing so, Umeme has embraced community initiatives in the areas of Health, Safety, Environment and Humanitarian Aid."
                  keywords="CSR , Changing lives, charity organizations, Environmental, sustainability, Health Safety, Humantarian Aid"
                />
                <CsrPage />
              </SF>
            )}
          />
          <Route
            exact
            path="/corporate-governance"
            render={() => (
              <SF>
                <Seo
                  title="Corporate Governance"
                  description="The Board of Directors (‘Board’) of Umeme limited understands and appreciates that sound governance practices are critical to the sustainability of the company. The company espouses best in good governance standards to further its operations and dealings with regulators, customers, Government, suppliers, financing agencies, partners, shareholders, stakeholders and the general public."
                  keywords=" Board of Directors, Board committees, Government, operations"
                />
                <CgPage />
              </SF>
            )}
          />
          <Route
            exact
            path="/corporate-governance/senior-management-team"
            render={() => (
              <SF>
                <Seo
                  title="Senior Management Team"
                  description="The Board of Directors (‘Board’) of Umeme limited understands and appreciates that sound governance practices are critical to the sustainability of the company. The company espouses best in good governance standards to further its operations and dealings with regulators, customers, Government, suppliers, financing agencies, partners, shareholders, stakeholders and the general public."
                  keywords=" Board of Directors, Board committees, Government, operations"
                />
                <SmtPage />
              </SF>
            )}
          />
          <Route
            exact
            path="/corporate-governance/board-of-directors"
            render={() => (
              <SF>
                <Seo
                  title="Board of Directors"
                  description="The Board of Directors (‘Board’) of Umeme limited understands and appreciates that sound governance practices are critical to the sustainability of the company. The company espouses best in good governance standards to further its operations and dealings with regulators, customers, Government, suppliers, financing agencies, partners, shareholders, stakeholders and the general public."
                  keywords=" Board of Directors, Board committees, Government, operations"
                />
                <BodPage />
              </SF>
            )}
          />
          <Route
            exact
            path="/corporate-governance/board-committee/:id"
            render={(props) => (
              <SF>
                <BcPage {...props} />
              </SF>
            )}
          />
          <Route
            exact
            path="/customers"
            render={() => (
              <SF>
                <Seo
                  title="Customers"
                  description="This section outlines services that cater to all your Businesses, domestic or home electricity requirements. All information on tariffs (rates and estimates), domestic outages, help and support, self-service tools & options are included in this section. You can also initiate a request for connection."
                  keywords=" Domestic, Businesses, Outage Alerts, Calculate usage"
                />
                <CustomersPage />
              </SF>
            )}
          />
          <Route
            exact
            path="/projects"
            render={() => (
              <SF>
                <Seo
                  title="Capital projects"
                  description="Learn about our unique combination of funding, industry expertise and 
commercial opportunities
"
                  keywords="Projects, Businesses, Commercial, opportunities"
                />
                <CapitalProjectsPage />
              </SF>
            )}
          />
          <Route
            exact
            path="/project/:id"
            render={(props) => (
              <SF>
                <ProjectPage {...props} />
              </SF>
            )}
          />

          <Route
            exact
            path="/careers"
            render={() => (
              <SF>
                <Seo
                  title="Careers & Volunteers"
                  description="Join an exciting team of over 2000 talented professionals working across the country to care for our customers in line with our vision of Powering Communities, Business and Industry for a prosperous Uganda"
                  keywords="Jobs, opportunities, vacancies, work, Engineering, Customer service and sales, Graduates and Undergraduates"
                />
                <CareersPage />
              </SF>
            )}
          />
          <Route
            exact
            path="/suppliers"
            render={() => (
              <SF>
                <Seo
                  title="Suppliers"
                  description="Information, for suppliers"
                  keywords="Suppliers, bids, tenders"
                />
                <SuppliersPage />
              </SF>
            )}
          />
          <Route
            exact
            path="/large_power_users"
            render={() => (
              <SF>
                <Seo
                  title="Large Power Users"
                  description="Large Power Users"
                  keywords="LPU, Large Power Users"
                />
                <LargePowerUsersPage />
              </SF>
            )}
          />
          <Route
            exact
            path="/domestic_customers"
            render={() => (
              <SF>
                <Seo
                  title="Domestic Customers"
                  description="Domestic Customers"
                  keywords="Domestic Customers, users"
                />
                <DomesticCustomers />
              </SF>
            )}
          />

          <Route
            exact
            path="/cookie_policy"
            render={() => (
              <SF>
                <Seo
                  title=" Cookie Policy"
                  description="cookie policy"
                  keywords="cookie policy, privacy"
                />
                <Cookie_policy />
              </SF>
            )}
          />
          <Route
            exact
            path="/privacy_policy"
            render={() => (
              <SF>
                <Seo
                  title=" Privacy Policy"
                  description="Privacy policy"
                  keywords="Privacy policy, privacy"
                />
                <Privacy_policy />
              </SF>
            )}
          />
          <Route
            exact
            path="/e-sefuliya"
            render={() => (
              <SF>
                <Seo
                  title=" e-Sefuliya"
                  description="Get yourself e-Sefuliya to save on your energy bills"
                  keywords="e-cooking ,e-Sefuliya, cooking, save power,EPC, e-Sefuliya copilot project"
                />
                <E_Sefuliya />
              </SF>
            )}
          />
          <Route
            exact
            path="/yaka_upgrade"
            render={() => (
              <SF>
                <Seo
                  title="Yaka Meter Upgrade"
                  description="Yaka Upgrade Project"
                  keywords="yaka ,upgrade, tid, roll over,meter 2024, yaka_upgrade"
                />
                <TID />
              </SF>
            )}
          />
          <Route
            exact
            path="/success"
            render={() => (
              <SF>
                <Seo
                  title=" Success"
                  description="Get yourself e-Sefuliya to save on your energy bills"
                  keywords="e-cooking ,e-Sefuliya, cooking, save power,EPC , success"
                />
                <Success />
              </SF>
            )}
          />
          <Route
            exact
            path="/terms_of_use"
            render={() => (
              <SF>
                <Seo
                  title=" terms_of_use"
                  description="terms_of_use"
                  keywords="terms_of_use, terms and conditions"
                />
                <Terms_of_use />
              </SF>
            )}
          />

          <Route
            exact
            path="/careers/jobs"
            render={() => (
              <SF>
                <Seo
                  title="Careers & Volunteers"
                  description="Join an exciting team of over 2000 talented professionals working across the country to care for our customers in line with our vision of Powering Communities, Business and Industry for a prosperous Uganda"
                  keywords="Jobs, oppotunities, vacancies, work, Engineering, Customer service and sales, Graduates and Undergraduates"
                />
                <JobsPage />
              </SF>
            )}
          />
          <Route
            exact
            path="/careers/jobs/:id"
            render={(props) => (
              <SF>
                <JobPage {...props} />
              </SF>
            )}
          />
          <Route
            exact
            path="/careers/jobs/categories/:id"
            render={(props) => (
              <SF>
                <CategoryPage {...props} />
              </SF>
            )}
          />

          <Route
            exact
            path="/outages"
            render={() => (
              <SF>
                <Seo
                  title="Power Alerts & Outages"
                  description="While we work to ensure that our customers stay powered up, we regularly embark on maintenance practices e.g. maintenance of our equipment, hardware/software upgrades, troubleshootincidents & unplanned issues."
                  keywords="Outages, Ongoing, Power"
                />
                <OutagesPage />
              </SF>
            )}
          />
          <Route
            exact
            path="/tenders"
            render={() => (
              <SF>
                <Seo
                  title="Bids and Tenders"
                  description="Fill the form provided in either of the following ways:-
Offline: Download form, fill it and email it to:- procurementbids@umeme.co.ug
Online: Directly fill and submit the online form provided on our website
Bid documents will then be provided after our procurement office confirms receipt of your bid registration form"
                  keywords="Bids, Tenders, Procurement"
                />
                <TendersPage />
              </SF>
            )}
          />
          <Route
            exact
            path="/safety"
            render={() => (
              <SF>
                <Seo
                  title="Safety tips"
                  description="Umeme safety tips"
                  keywords="safety, tips"
                />
                <SafetyPage />
              </SF>
            )}
          />

          <Route
            exact
            path="/safety/:id"
            render={(props) => (
              <SF>
                <Seo
                  title="Safety tips"
                  description="Umeme safety tips"
                  keywords="safety, tips"
                />
                <TipPage {...props} />
              </SF>
            )}
          />

          <Route
            exact
            path="/help"
            render={() => (
              <SF>
                <Seo
                  title="Help Center"
                  description="How can we help? Browse our help desk, we are happy to get you started"
                  keywords="Faqs, Help, Get started, new to umeme"
                />
                <HelpPage />
              </SF>
            )}
          />
          <Route
            exact
            path="/help/faqs"
            render={() => (
              <SF>
                <Seo
                  title="Frequently asked questions"
                  description="How can we help?"
                  keywords="Faqs, Help, Get started, new to umeme"
                />
                <FaqsPage />
              </SF>
            )}
          />
          <Route
            exact
            path="/yakamangu"
            render={() => (
              <SF>
                <Seo
                  title="Yaka Mangu"
                  description="Government of uganda (gou) funded connections (yaka mangu) Frequently asked questions."
                  keywords="UMEME,yaka Mangu, Umeme and government, power, energy, yaka, amasanyalaze, electricity, Company, distribution, Uganda Securities Exchange, use, uganda, supply, customers, maintenance, Nairobi Securities Exchange, nse, East Africa, Africa, Kampala, Industrial, Commercial, Domestic, Stations"
                />
                <YakaMangu />
              </SF>
            )}
          />

          <Route
            exact
            path="/help/documents"
            render={() => (
              <SF>
                <Seo
                  title="Documents - Help Center"
                  description="How can we help?"
                  keywords="Help, Get started, new to umeme"
                />
                <DocumentsPage />
              </SF>
            )}
          />
          {/* <Route
            exact
            path="/calculator"
            render={() => (
              <SF>
                <Seo
                  title="Yaka! Units Calculator"
                  description="Calculate you next yaka payment"
                  keywords="Calculator, Yaka, Bill, Domestic, Units"
                />
                <CalculatorPage />
              </SF>
            )}
          /> */}
          <Route
            exact
            path="/payments"
            render={() => (
              <SF>
                <Seo
                  title="Make payments"
                  description="Designed to make your life easier by giving you control of prepaid and postpaid payments
Check out some useful links to help you with your prepaid and postpaid payment methods"
                  keywords="Payments, Online, Visa, MasterCard, Mobile money"
                />
                <MakePaymentsPage />
              </SF>
            )}
          />
          <Route
            exact
            path="/energy_distribution_map"
            render={() => (
              <SF>
                <Seo
                  title="Energy Distribution Map"
                  description="Map showing Umeme Service Centers, Districts, Substations and Network"
                  keywords="Service centers, Districts, Substation, Network, Uganda"
                />
                <EdmPage />
              </SF>
            )}
          />
          <Route
            exact
            path="/magazine"
            render={() => (
              <SFLite>
                <Seo
                  title="UMEME Pulse Magazine"
                  description="Magazine"
                  keywords="Magazine"
                />
                <MagazinePage />
              </SFLite>
            )}
          />
          <Route
            exact
            path={`/annual-report-${new Date().getFullYear() - 1}`} //we always get annual reports at the end of the year
            render={() => (
              <SFLite>
                <Seo
                  title="UMEME Annual report"
                  description="Umeme Annual report"
                  keywords="report"
                />
                <AnnualReports />
              </SFLite>
            )}
          />
          <Route
            exact
            path="/what-you-need-to-get-connected"
            render={() => (
              <SF>
                <Seo
                  title="What you need to Get Connected"
                  description="Apply, pay and get your new power connection in 10-15 working days!"
                  keywords="Power Connection, charges, application, registration, inspection"
                />
                <GetConnected />
              </SF>
            )}
          />
          <Route
            render={() => (
              <SF>
                <Seo
                  title="Not found"
                  description="We are sorry the page you are looking for was not found"
                  keywords=""
                />
                <NotFound />
              </SF>
            )}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}

class SF extends Component {
  render() {
    return (
      <ScrollToTop>
        <ErrorBoundary>
          <Suspense
            fallback={
              <div className="loading text-center">
                <div className="spacer" />
                <div className="spacer" />
                <div className="spacer" />
                <Logo />
                <span>Loading...</span>
              </div>
            }
          >
            <Layout>{this.props.children}</Layout>
          </Suspense>
        </ErrorBoundary>
      </ScrollToTop>
    );
  }
}

class SFLite extends Component {
  render() {
    return (
      <ScrollToTop>
        <ErrorBoundary>
          <Suspense
            fallback={
              <div className="loading text-center">
                <div className="spacer" />
                <div className="spacer" />
                <div className="spacer" />
                <Logo />
                <span>Loading...</span>
              </div>
            }
          >
            {this.props.children}
          </Suspense>
        </ErrorBoundary>
      </ScrollToTop>
    );
  }
}
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <React.Fragment>
          <div className="container-custom text-center">
            <h1 className="page-header text-center">Something went wrong.</h1>
            <a className="btn btn-primary text-center" href="/">
              Reload
            </a>
          </div>
        </React.Fragment>
      );
    } else {
      return this.props.children;
    }
  }
}

export default App;
