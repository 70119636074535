import { Component } from 'react';
import ReactGA from 'react-ga';
import withRouter from 'react-router-dom/withRouter';

class ScrollToTop extends Component {
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
    componentDidUpdate(prevProps) {
      if (this.props.location.pathname !== prevProps.location.pathname) {
        window.scrollTo(0, 0);
      }
     // let path = window.location.pathname;
      // if(path ==="/"){
      //  document.title= "UMEME - Powering Uganda"
      // }else{
      //   let newPath = path.substr(1);
      //   document.title= this.capitalizeFirstLetter(newPath)+" - UMEME - Powering Uganda"
      // }

      ReactGA.initialize('UA-139940854-1');
      ReactGA.pageview(window.location.pathname + window.location.search);

    }
    componentDidMount(){
      ReactGA.initialize('UA-139940854-1');
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    render() {
      return this.props.children;
    }
  }
  
  export default withRouter(ScrollToTop);